import adminApi from "./admin.api";
import qs from 'qs'

export default {
    /**
     * Consulta y filtra la lista de cobranzas
     * @param {Object} query
     * @returns {Promise}
     */
    all(query = {}) {
        return adminApi.api.get(`${adminApi.baseURL}/cobranzas?${qs.stringify(query)}`)
    },

    /**
     * Consulta una sola cobranza
     * @param {int} cobranza
     * @returns {Promise}
     */
    find(cobranza) {
        return adminApi.api.get(`${adminApi.baseURL}/cobranzas/${cobranza}`)
    },

    /**
     * Editar cobranza
     * @param cobranza
     * @param params
     * @returns {*}
     */
    update(cobranza, params) {
        return adminApi.api.get(`${adminApi.baseURL}/cobranzas/${cobranza}`, params)
    },

    /**
     * Obtiene filtros de cobranza
     * @param query
     * @returns {*}
     */
    filters(query = {}) {
      return adminApi.api.get(`${adminApi.baseURL}/cobranzas/filtros`)
    }
}
